import React  from "react";
import './assetss/css/App.css';
import './assetss/css/Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import Inicio from './components/Inicio';
import Clientes from './components/Clientes/Clientes';
import Creditos from './components/Creditos';
import Extracto from './components/Reportes/Extracto';
import Credito from "./components/Reportes/Credito";
import Informes from './components/Informes';
import Ajustes from './components/Ajuste/Ajustes';
import Simulacion from './components/Simulacion/Simulacion';
import NotFound from "./components/NotFound";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import Footer from "./components/Footer";
import Sidebar from "./components/Header/Sidebar";
import Root from "./components/root/Root";

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

function Main() {

  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const isRootPage = location.pathname  === '/root'

  return (
    <React.Fragment>
      {!isLoginPage && !isRootPage && <Sidebar modulo={location.pathname} />}
      <Routes>
        <Route path='/' exact element={<Login />} />
        <Route key="inicio" path='/inicio' exact element={ <Inicio modulo="inicio" /> } />
        <Route key="clientes" path='/clientes' exact element={ <Clientes modulo="clientes" /> } />
        <Route key="creditos" path='/creditos' exact element={ <Creditos modulo="creditos" /> } />
        <Route key="informes" path='/informes' exact element={ <Informes modulo="informes" /> } />
        <Route key="extracto" path='/extracto' exact element={ <Extracto modulo="extracto" /> } />
        <Route key="credito" path='/credito' exact element={ <Credito modulo="credito" /> } />
        <Route key="ajustes" path='/ajustes' exact element={ <Ajustes modulo="ajustes" /> } />
        <Route key="simulacion" path='/simulacion' exact element={ <Simulacion modulo="simulacion" /> } />
        <Route key="root" path='/root' exact element={ <Root modulo="root" /> } />
        <Route key="notFound" path="*" element={ <NotFound /> } />
      </Routes>
      {!isLoginPage && <Footer />}
    </React.Fragment>
  );
}

export default App;