import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { alerta, formatearMonedaValue } from "../../services/utilities";
import { eliminar_solicitud }  from "../../services/Credito";
import { solicitudes } from "../../services/Client"
import { SetSolicitudCredito } from "../../actions";
import { useNavigate } from 'react-router-dom';
import { cerrarModal} from "../../services/utilities"

function ModalSolicitudesCredito(props) {

    const [dataSolicitudes, setDataSolicitudes] = useState([]);
    const navigate = useNavigate();

    useEffect( () =>{
        
        if(props.idCliente){
            
            solicitudes(props.idCliente).then(response => {
                if(response.status === 200){
                    setDataSolicitudes(response.data.data)
                }else{
                    alerta('Error al cargar la lista de clientes', response.message)
                }
            }).catch(error =>{
                alerta('Error al cargar la lista de clientes', error.message)
            }). finally(
                
            )
        }
        
    },[props.idCliente])

    const handleEliminarSolicitud = async (id_solicitud) => {

        try {
            await eliminar_solicitud(id_solicitud).then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    cerrarModal('ModalSolicitudesCredito');
                }
            });
        }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alert();
        }
    }

    const handleSimularSolicitud = async (item) => {
        props.SetSolicitudCredito(item);
        navigate('/simulacion');        
    }
    
    return(

        <React.Fragment>
           <div className="modal fade" id="ModalSolicitudesCredito" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h5 id="staticBackdropLabel" className='m-0'>Solicitudes de crédito</h5>
                        </div>
                        <div className="modal-body">
                            {dataSolicitudes.length === 0 ?
                                <div className='text-center text-secondary my-4  align-items-center'>
                                    <h3>El cliente no tiene solicitudes pendientes.</h3>
                                    <p>Por favor, aplique otro filtro de búsqueda.</p>
                                </div>
                            :
                            <table className='table table-hover'>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Valor</th>
                                        <th>Cuotas</th>
                                        <th>Interes</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataSolicitudes.map((solciitud, index) => (
                                        <tr key={index}>
                                            <td>{solciitud.fecha}</td>
                                            <td>${formatearMonedaValue(solciitud.valor)}</td>
                                            <td>{solciitud.cuotas}</td>
                                            <td>{solciitud.interes}</td>
                                            <td>
                                                <button className="card-link btn btn-primary btn-sm" data-bs-dismiss="modal" onClick={() => handleSimularSolicitud(solciitud)}>Simular</button>
                                            </td>
                                            <td>
                                                <button className="card-link btn btn-danger  btn-sm" onClick={() => handleEliminarSolicitud(solciitud.id_credito_solicitud)}><i className="bi bi-trash"></i> Elminar</button>
                                            </td>                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>                            
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    SetSolicitudCredito
};

export default connect(null, mapDispatchToProps)(ModalSolicitudesCredito)
