import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setSaldosEmpresa } from '../../actions'
import { traslado_saldo_intereses } from "../../services/Ajustes"
import { alerta, toast, cerrarModal } from "../../services/utilities";
import Swal  from 'sweetalert2';
import { validarSegundaClave } from '../../services/Login';
import Sidebar from './Sidebar';
import ListaEmpresas from './ListaEmpresas';
import NuevaEmpresa from './NuevaEmpresa';
import EditarEmpresa from './EditarEmpresa';


function Root(props){

    return(
        <React.Fragment>
            <Sidebar></Sidebar>
            <div className='container-creditos'>
                <div className='row'>
                    <div className='col-md-12'>
                        <ListaEmpresas></ListaEmpresas>
                    </div>
                </div>
            </div>                
            <button
                style={{position: 'fixed',bottom: '20px',right: '20px', 'zIndex': '1000'}}
                className="btn btn-success me-2"
                data-bs-toggle="modal" 
                data-bs-target="#ModalNuevaEmpresa"          
                >
                <i className="bi bi-building me-2"></i>Crear Nueva Empresa
            </button>
            <NuevaEmpresa></NuevaEmpresa>
            <EditarEmpresa></EditarEmpresa>
        </React.Fragment>
    );
}



export default connect(null, null)(Root)
