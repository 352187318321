import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { SetSolicitudCredito } from "../../actions";
import { solicitudes, get_count, eliminar_solicitud }  from "../../services/Credito";
import  avatar_default from  "../../assetss/img/logo_aliens.png"
import Swal  from 'sweetalert2';
import { formatearMonedaValue } from "../../services/utilities";
import { useNavigate } from 'react-router-dom';

function UserOptions(props){

    const [data, setSolciitudes] = useState([]);
    const [count, setCount] = useState(0);
    const navigate = useNavigate();


    useEffect( () =>{
        handleGetCount();
    },[]);

    const handleConsultarSolicitudes = async () => {

        try {
            await solicitudes().then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    setSolciitudes(response.data.data);
                }
            });
        }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
        }
    }

    const handleGetCount = async () => {

        try {
            await get_count().then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    setCount(response.data.count);
                }
            });
        }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
        }
    }

    const handleLogout = () => {

        
        //localStorage.setItem("token", localStorage.getItem("token_gestionador"));
        if(localStorage.getItem("gestion") === 'SI'){
            localStorage.setItem("gestion",'NO');
            localStorage.setItem("token", localStorage.getItem("token_gestionador"));
            localStorage.removeItem('token_gestionador');
            window.location.href = '/root';
        }else{
            Swal.fire({
                icon: 'question',
                html: '<p style="font-size:16px"><b>Cerrar Sesion</b><br>¿Seguro desea salir?</p>',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: '<span style="font-size:16px"><strong>Aceptar</strong></span>',
                cancelButtonText: '<span style="font-size:16px"><strong>Cancelar</strong></span>',
                width: 350,
                height:250
            }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.clear();
                        window.location.href = '/';
                    }
            })
        }
    }

    const handleEliminarSolicitud = async (id_solicitud) => {

        try {
            await eliminar_solicitud(id_solicitud).then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    handleGetCount();
                    handleConsultarSolicitudes();
                }
            });
        }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alert();
        }
    }

    const handleSimularSolicitud = async (item) => {
        props.SetSolicitudCredito(item);
        navigate('/simulacion');        
    }

    
    
    return(
        <React.Fragment>

            <div className="dropdown">
                <button
                    type="button"
                    className="btn btn-primary btn-md position-relative mx-2 dropdown-toggle mt-1"
                    id="dropdownNotifButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={handleConsultarSolicitudes}
                >
                    <i className="bi bi-bell"></i>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {count}+
                    </span>
                </button>
                <ul className="dropdown-menu dropdown-menu-notif" id="dropdownNotifMenu">
                    {Array.isArray(data) && data.length >  0 ? data.map((item, index) => (
                        <li key={index} className="mb-2">
                            <div className="card text-bg-light  border-success">
                                <div className="card-body">
                                    <h5 className="card-title">Solicitud de crédito <span style={{'fontSize':'14px', 'color':'grey'}}>{item.fecha}</span></h5>
                                    <h6 className="card-subtitle mb-0"><b>{item.nombre} {item.apellido}</b></h6>
                                    <h6 className="card-subtitle mb-0"><b>{item.tipo_documento} {item.numero_documento}</b></h6>
                                    <p className="card-text m-0">Valor <span>${formatearMonedaValue(item.valor)}</span></p>
                                    <p className="card-text m-0">Cuotas <span>{item.cuotas}</span></p>
                                    <p className="card-text m-0">interes <span>%{item.interes}</span></p>
                                    <button className="card-link btn btn-primary btn-sm" onClick={() => handleSimularSolicitud(item)}>Simular</button>
                                    <button className="card-link btn btn-danger  btn-sm" onClick={() => handleEliminarSolicitud(item.id_credito_solicitud)}><i className="bi bi-trash"></i> Elminar</button>
                                </div>
                            </div>
                        </li>
                    )) : 
                        <li><p className='text-center'>Actualmente, no tienes notificaciones.</p></li>
                    }                    
                </ul>
            </div>
            <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img height="22" className="rounded-circle" src={avatar_default} />
                    <span className="text-white p-1">
                        {props.perfil} - {props.nickname}
                    </span>
                </button>
                <ul className="dropdown-menu dropdown-menu-perfil dropdown-menu-dark">
                    {props.perfil === 'ADMIN' && (
                        <li>
                            <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalAjustedeUsuario">
                                <i className="bi bi-people"></i> Usuarios
                            </a>
                        </li>
                    )}
                    {props.perfil === 'ADMIN' && (
                        <li>
                            <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalCrearUsuario">
                                <i className="bi bi-person-add"></i> Crear usuario
                            </a>
                        </li>
                    )}
                    <li>
                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalCambioDeContrasena">
                            <i className="bi bi-key"></i> Cambiar Contraseña
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#" onClick={handleLogout}>
                            <i className="bi bi-power"></i> Cerrar Sesión
                        </a>
                    </li>
                </ul>
            </div>  
        </React.Fragment>
    )
}

const mapDispatchToProps  = {
    SetSolicitudCredito
};

const mapSateToProps = state => {

    return {
        gestionando: state.gestionando,
    }
}


export default connect(mapSateToProps, mapDispatchToProps)(UserOptions)