import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setIdComercioGestionado, setGestionando, setIdEmpresa } from '../../actions'
import Swal  from 'sweetalert2';
import { getListaEmpresas, generarTokenGestion } from '../../services/Root'
import { alerta, toast, formatDate } from '../../services/utilities';

function ListaEmpresas(props){

    const token = localStorage.getItem("token")
    const gestion = localStorage.getItem("gestion")
    
    useEffect(() => {

        if(props.id_comercio_gestionado === undefined || props.id_comercio_gestionado == 0){
            handleCargarDataModulo();
        }else{

            Swal.fire({
                title: "¿ Esta seguro de activar la gestion ?",
                text: "Si esta seguro presione el boton Activar de lo contrario Cancelar",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Activar'
            }).then((result) => {
                if (result.isConfirmed) {
                    generarTokenGestion(props.id_comercio_gestionado).then(response => {
                        if(response.status !== 200){
                            alerta('Se presento un error al intentar gestionar el comercio',response.response.data.status_message??response.message);
                        }else{
                            props.setGestionando('SI')
                            localStorage.setItem("token_gestionador",token);
                            localStorage.setItem("token", response.data.jwt)
                            localStorage.setItem("gestion",'SI');
                            localStorage.setItem("id_comercio_gestionado", props.id_comercio_gestionado );
                            window.location.href = '/inicio';
                        }
                    });
                }else{
                    props.setIdComercioGestionado(0);
                    props.setGestionando('NO');
                }
            });
        }
    },[props.id_comercio_gestionado]);

    const [spiner, setSpiner] = useState(false);
    const [listaEmpresas, setListaEmpresas] = useState([]);

    const handleGestionComercio = (id_usuario) => {
        props.setGestionando('SI');
        props.setIdComercioGestionado(id_usuario);
    }

    const handleCargarDataModulo = async () => {

        setSpiner(true);

        try {
            await getListaEmpresas().then(response => {
                if(response.status === 200){
                    setListaEmpresas(response.data.data)
                }else{
                    alerta('No fue posible cargar la lista de empresas','','warning');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de empresas  (root/lista/empresas) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }
    
    return(
        <React.Fragment>
            <div className="row">
                {Array.isArray(listaEmpresas) && listaEmpresas.length > 0  ? listaEmpresas.map((empresa, index) => (
                    <div className="col-md-4 mt-4" key={index}>
                        <div className="card">
                            <div className="d-flex justify-content-center align-items-center">
                                <i className="bi bi-building"></i>
                                <h5 className="card-header">{empresa.nombre}</h5>
                            </div>
                            <div className="card-body">
                                <span className={empresa.estado_id === 1 ? 'text-success' : 'text-danger'}><b>{empresa.estado_id === 1 ? 'Activa' : 'Inactiva'}</b></span><br/>
                                <div className="d-flex align-items-center" style={{height:'20px'}}>
                                    <i className="card-title bi bi-globe me-2"></i>
                                    <a href={empresa.dominio} target="blank_"><h5 className="card-title">{empresa.dominio}</h5></a>
                                </div>
                                <i className="bi bi-calendar3 me-2"></i>
                                <b>{empresa.fecha_creacion}</b><br/>
                                <i className="bi bi-envelope-at me-2"></i>
                                <b>{empresa.correo}</b>
                                <hr></hr>
                                <button 
                                    type="button" 
                                    className="btn btn-outline-dark btn-md me-2" 
                                    title="Gestion Usuario" 
                                    data-id={empresa.id_usuario}
                                    onClick={(e) => handleGestionComercio(e.target.getAttribute('data-id'))}                            
                                >   
                                    Gestionar
                                    <i 
                                        className="mx-2 bi bi-eye-fill"  
                                        data-id={empresa.id_usuario}                                           
                                    >
                                    </i>
                                </button>
                                <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#ModalEditarEmpresa"
                                    data-id={empresa.id_empresa} 
                                    onClick={(e) => props.setIdEmpresa(e.target.getAttribute('data-id'))}
                                >Editar
                                </button>
                            </div>
                        </div>
                    </div>
                )) : <p className="text-center">cargando...</p>}
                {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{height:300}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                :''}
            </div>
        </React.Fragment>
    );
}


const mapDispatchToProps  = {
    setIdComercioGestionado,
    setGestionando,
    setIdEmpresa
};

const mapSateToProps = state => {

    return {
        gestionando: state.gestionando,
        id_comercio_gestionado: state.id_comercio_gestionado
    }
}


export default connect(mapSateToProps,mapDispatchToProps)(ListaEmpresas);