import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_credito, get_valor_pago } from "../../services/Credito"
import { get_bancos} from "../../services/Login"
import { pagar_credito } from "../../services/CreditoFile"
import { setIdCredito, setSaldosEmpresa } from '../../actions';
import Swal  from 'sweetalert2';
import { toast, alerta, abrirModal, cerrarModal, formatearMonedaValue, formatDate } from "../../services/utilities"

function ModalRegistrarPago(props) {

    useEffect(() => {
        setSpiner(true);
        handleConsultarCredito()
        handleConsultarBancos()
        setToken(null);
    }, [props.idCredito]);

    const [spiner, setSpiner] = useState(false);
    const [procesando, setProcesando] = useState(false);
    const [hayData, setHayData] = useState(false);
    const [noFoundCredito, setNoFoundCredito] = useState(false);
    const [bancos, setBnacos] = useState([]);
    const [disbaledValor, setDisbaledValor] = useState(true);
    const [deudaActual, setDeudaActual] = useState();
    const [credito, setCredito] = useState({
        "credito":{},
        "cliente":{}
    });

    const [pago, setPago] = useState({
        "id_credito" : 0,
        "fecha_pago" : formatDate(new Date()),
        "tipo_pago" : "",
        "banco_id" : "",
        "cuenta_ingreso": "",
        "img": "",
        "observacion" :"",
        "valor": 0
    });

    const [token, setToken] = useState(null);

    const handleInputPagoChange = (event) => {

        const { name, value, type } = event.target;
        
        if(name === 'valor'){
            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            setPago({ ...pago, [name]: formattedNumber });
        }else{
            setPago({ ...pago, [name]: value });
        }

        if(name === "tipo_pago" && value !==""){
            handleConsultarValorPago(credito.credito.id_credito, value, pago.fecha_pago);
            setDisbaledValor(value != '3');
        }

        if(name === "fecha_pago" && pago.tipo_pago !==""){
            handleConsultarValorPago(credito.credito.id_credito, pago.tipo_pago, value);
            setDisbaledValor(pago.tipo_pago != '3');
        }

    };

    const handleConsultarBancos = async () => {
        
        try {
            await get_bancos().then(response => {
                if(response.status === 200){
                    setBnacos(response.data.data);
                }else{
                    toast('Error al cargar la lista de bancos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }

    const handleConsultarCredito = async () => {

        try {
            await get_credito(props.idCredito).then(response => {
                if(response.status === 200 && response.data.status_message == 'ok'){
                    setHayData(true);
                    setCredito(response.data.data);
                    setDeudaActual(response.data.data.credito.deuda_interes)
                }else{
                    setNoFoundCredito(true)
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            //setMsg('Error al cargar la información del crédito', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    const handleConsultarValorPago = async (id_credito, tipo_pago, fecha_pago) =>{
        try {
            await get_valor_pago(id_credito, tipo_pago, fecha_pago).then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){

                    var value = response.data.valor_pago;
                    var inputValue = value.replace(/\D/g, '');
                    const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    setPago((pago) => ({
                        ...pago,
                        valor: formattedNumber,
                        tipo_pago: tipo_pago,
                        id_credito: id_credito,
                      }));

                    if(value == 0 && tipo_pago == 2){
                        toast('El crédito está al día', 'Por favor, seleccione un tipo de pago diferente.', true, 'success');
                    }

                }else{
                    toast(response.data.status_message,'Por favor intente nuevamente')
                    setPago((pago) => ({
                        ...pago,
                        valor: 0,
                        tipo_pago: tipo_pago,
                        id_credito: id_credito,
                      }));
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al cargar la información de pago', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }

    const handleRealizarPago = async () =>{
        
        if(pago.tipo_pago == ''){
            toast('Campos Requeridos','Seleccione un tipo de pago', true)
            return;
        }

        if(pago.fecha_pago == ''){
            toast('Campos Requeridos','Seleccione una fecha de pago', true)
            return;
        }

        if(pago.banco_id == ''){
            toast('Campos Requeridos','Seleccione un banco', true)
            return;
        }

        if(pago.cuenta_ingreso == ''){
            toast('Campos Requeridos','Digite una cuenta de ingreso', true)
            return;
        }

        if(pago.valor == '' || pago.valor == '0'){
            toast('Campos Requeridos','El valor debe ser mayor a cero', true)
            return;
        }

        /*if(pago.img == ''){
            toast('Campos Requeridos','Debe adjuntar una imagen de comprobante', true)
            return;
        }*/

        if(pago.observacion == ''){
            toast('Campos Requeridos','Digite una observación', true)
            return;
        }

        let fechaHoyCadena = pago.fecha_pago;
        let partesFecha = fechaHoyCadena.split('-');
        let fechaHoy = new Date(partesFecha[0], partesFecha[1] - 1, partesFecha[2]);
        let dia = fechaHoy.getDate();

        if(dia != credito.credito.dia_de_pago && props.usuario.perfil_id !=2 && token == null){
            
            cerrarModal('modalRealizarPago');

            Swal.fire({
                title: 'Token',
                text:'"Esta operación requiere autorización."',
                input: 'text',
                inputPlaceholder: 'TOKEN',
                inputValue: '',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                buttonsStyling: false,
                inputAttributes: {
                    maxlength: 4,
                    style: 'font-size: 25px; letter-spacing: 12px; color: #333; border-radius: 5px; text-align: center; line-height: 30px;'
                },
                customClass: {
                    confirmButton: "btn btn-success btn-lg mx-2",
                    cancelButton: "btn btn-secondary btn-lg mx-2"
                },
                inputValidator: (value) => {
                    if (!value) {
                        return 'Debes ingresar un token';
                    }
                    if (value.length !== 4) {
                        return 'El valor debe token tener exactamente 4 caracteres';
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    setToken(result.value);
                    handleConfirmarPago(result.value);
                    abrirModal('modalRealizarPago');                 
                }else{
                    abrirModal('modalRealizarPago');
                }
            });
        }else{
            handleConfirmarPago(token);
        }
    }

    const handleConfirmarPago = async (token_pago) => {

        try {

            const formData = new FormData();

            formData.append('id_credito', pago.id_credito);
            formData.append('fecha_pago', pago.fecha_pago);        
            formData.append('tipo_pago', pago.tipo_pago);
            formData.append('banco_id', pago.banco_id);
            formData.append('cuenta_ingreso', pago.cuenta_ingreso);
            formData.append('img', pago.img);
            formData.append('observacion', pago.observacion);
            formData.append('valor', pago.valor);
            formData.append('token', token_pago);

            setProcesando(true);

            await pagar_credito(formData).then(response => {

                if(response.status === 201){

                    cerrarModal('modalRealizarPago')

                    Swal.fire({
                        title: "Pago exitoso",
                        text: "Presione 'Aceptar' para ir al módulo de créditos.",
                        icon: "success",
                        confirmButtonColor: '#198754',
                        confirmButtonText: 'Aceptar',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/creditos';
                        }
                    });

                }else{
                    setToken(null);
                    alerta('Error al realizar el pago',response.data.status_message);
                }
                setProcesando(false);
            });
        }catch (error) {
            setProcesando(false);
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al realizar el pago', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

    }
    
    return(

        <React.Fragment>
           <div className="modal fade" id="modalRealizarPago" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h5 className='m-0'>Pago del credito <span className="badge text-bg-info"><i className="bi bi-upc"></i>{credito.credito.id_credito}</span></h5>                         
                        </div>
                        <div className="modal-body">
                            <form encType="multipart/form-data">
                                <div className='row p-2'>
                                    <div className='col-md-6'>
                                        <label className="form-label" >Fecha del pago: <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input 
                                                type="date" 
                                                className="form-control form-control-lg" 
                                                id="fecha_pago" 
                                                name="fecha_pago" 
                                                value={pago.fecha_pago}
                                                onChange={handleInputPagoChange} 
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label className="form-label" htmlFor="tipo_pago">Tipo de pago: <span className='text-danger'>*</span></label>
                                        <select 
                                            className="form-select form-select-lg text-center" 
                                            name="tipo_pago" 
                                            value={pago.tipo_pago}
                                            onChange={handleInputPagoChange}                                     
                                        >
                                            <option value="" disabled>- Seleccione -</option>
                                            <option value="2">Pago mínimo</option>
                                            <option value="4">Pago total</option>
                                            <option value="3">Otro valor</option>
                                        </select>
                                    </div>                                    
                                </div>
                                <div className='row p-2'>                                
                                    <div className='col-md-4'>
                                        <label className="form-label"  htmlFor="banco_id">Banco ingreso: <span className='text-danger'>*</span></label>
                                        <select 
                                            className="form-select form-select-lg text-center" 
                                            name="banco_id"
                                            id="banco_id" 
                                            value={pago.banco_id} 
                                            onChange={handleInputPagoChange}                                     
                                        >
                                            <option value="">- Seleccione -</option>
                                            {Array.isArray(bancos) ? bancos.map((item, index) => (
                                                <option key={index} value={item.id_banco}>{item.nombre}</option>
                                            )) : ''}
                                        </select>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className="form-label">Cuenta de ingreso: <span className='text-danger'>*</span></label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-lg" 
                                            id="cuenta_ingreso" 
                                            name="cuenta_ingreso" 
                                            value={pago.cuenta_ingreso}
                                            onChange={handleInputPagoChange} 
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <label className="form-label" >Comprobante:</label>
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            className="form-control form-control-lg" 
                                            id="img" 
                                            name="img"
                                            onChange={handleInputPagoChange} 
                                        />
                                    </div>
                                </div>
                                <div className='row p-2'>
                                    <div className='col-md-6'>
                                        <label className="form-label" >Valor: <span className='text-danger'>*</span></label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-lg" 
                                            id="valor" 
                                            name="valor" 
                                            value={pago.valor}
                                            style={{fontSize:'32px'}}
                                            disabled={pago.tipo_pago == 4 || pago.tipo_pago == 2 || pago.tipo_pago == ''}
                                            onChange={handleInputPagoChange} 
                                        />
                                        {pago.tipo_pago == 3 ?<span className='text-success'><b>* El valor debe ser igual o superior al pago mínimo.</b></span>:''}
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='d-flex align-items-center justify-content-between  m-0'>
                                                    <h6 className="card-subtitle text-body-secondary m-0">Día de pago</h6>
                                                    <p className='m-0'><b>Los {credito.credito.dia_de_pago} de cada mes</b></p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between  m-0'>
                                                    <h6 className="card-subtitle text-body-secondary m-0">Ultimo pago</h6>
                                                    <p className='m-0'><b>{credito.credito.fecha_ultimo_pago}</b></p>
                                                </div>                                                
                                                <div className='d-flex align-items-center justify-content-between  m-0'>
                                                    <h6 className="card-subtitle text-body-secondary m-0">Saldo Actual</h6>
                                                    <p className='m-0'><b>$ {formatearMonedaValue(credito.credito.saldo_actual)}</b></p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between  m-0'>
                                                    <h6 className="card-subtitle text-body-secondary m-0">Cuota mensual</h6>
                                                    <p className='m-0'><b>${formatearMonedaValue(credito.credito.cuota_mensual)}</b></p>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>
                                <div className='row p-2'>
                                    <div className='col-md-12'>
                                        <label className="form-label" >Observación: <span className='text-danger'>*</span></label>
                                        <textarea 
                                            type="text" 
                                            className="form-control form-control-lg" 
                                            id="observacion" 
                                            name="observacion" 
                                            value={pago.observacion}
                                            onChange={handleInputPagoChange} 
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-target="#modalGestionCredito" 
                                data-bs-toggle="modal"
                                >Atras
                            </button>
                            <button 
                                className="btn btn-success btn-lg" 
                                onClick={handleRealizarPago}
                                disabled={credito.credito.estato_id == 4 || procesando == true}
                                >Registrar Pago
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        idCredito: state.idCredito,
        usuario: state.usuario
    }
}

const mapDispatchToProps  = {
    setIdCredito,
    setSaldosEmpresa
};

export default connect(mapSateToProps, mapDispatchToProps)(ModalRegistrarPago)
