import InstaceAxios  from "./Api";

export const getListaEmpresas = async () => {
    const result = await InstaceAxios.get(`root/empresas`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getEmpresa = async (id_empresa) => {
    const result = await InstaceAxios.get(`root/empresa?id_empresa=${id_empresa}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const generarTokenGestion = async (id_usuario) => {
    const result = await InstaceAxios.get(`usuario/token_gestion?id_usuario=${id_usuario}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crear_empresa = async (data) => {
    const result = await InstaceAxios.post(`root/empresa/crear`, data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editar_empresa = async (data) => {
    const result = await InstaceAxios.post(`root/empresa/editar`, data)
        .then(res => res)
        .catch(error => error)
    return result
}